var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upgrade-message-vega" }, [
    _c(
      "div",
      { staticClass: "flex flex-column justify-center items-center mat-100" },
      [
        _c(
          "div",
          { staticClass: "flex flex-row justify-center items-center" },
          [
            _c("div", { staticClass: "lock mr-10" }, [_c("lock-icon")], 1),
            _c(
              "div",
              { staticClass: "flex flex-column justify-center items-center" },
              [
                _c("div", {
                  staticClass: "plan-info",
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("upgrade-messages.vega")),
                  },
                }),
                _c(
                  "div",
                  { staticClass: "upgrade-button mt-4" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/payment" } },
                      [
                        _c(
                          "vs-button",
                          { attrs: { color: "primary", type: "filled" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex flex-row justify-center items center",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "upgrade-icon" },
                                  [_c("upgrade-icon")],
                                  1
                                ),
                                _c("div", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t(
                                          "upgrade-messages.upgrade-your-plan"
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }